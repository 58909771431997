export default {
  "nav": {
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])},
    "spec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة الاخصائيين"])},
    "direct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادارة الاستشارات المباشرة"])},
    "treat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادارة الخطط العلاجية"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادارة مجموعة الدعم"])},
    "earn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحسابات المالية والارباح"])},
    "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسائل التواصل"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحفظة"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملف الشخصي"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الخروج"])}
  },
  "year": {
    "jan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يناير"])},
    "feb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فبراير"])},
    "march": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مارس"])},
    "april": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابريل"])},
    "May": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مايو"])},
    "jun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يونيو"])},
    "july": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوليو"])},
    "aug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أغسطس"])},
    "sep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سبتمبر"])},
    "oct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أوكتوبر"])},
    "nov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوفمبر"])},
    "dec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ديسمبر"])}
  },
  "common": {
    "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الشركة"])},
    "noCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد اسم شركة"])},
    "noGender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد نوع محدد"])},
    "noAge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد سن محدد"])},
    "selectedItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تخصصات مختارة"])},
    "ibanNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب أن يكون رقم الأيبان اكبر من 24 رقم"])},
    "comNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" يجب أن يكون رقم الترخيص أكبر من ٦ أرقام"])},
    "noMessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد رسائل"])},
    "noSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد مجموعات دعم"])},
    "noTreatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد خطط علاجية"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكل"])},
    "urgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاستشارات المباشرة"])},
    "plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخطط العلاجية"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف"])},
    "cancell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إالغاء"])},
    "deleteDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل انت متأكد من حذف الأخصائي ؟"])},
    "refuseReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سبب الإلغاء"])},
    "editSpec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الأخصائي"])},
    "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السابق"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التالي"])},
    "reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حجز جميع المقاعد"])},
    "phoneValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب أن يكون رقم الهاتف اكثر من ٩ أرقام"])},
    "emailValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى ادخال صيغة بريد الكتروني صالحة"])},
    "addApoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى ادخال المواعيد"])},
    "addSpec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة اخصائي"])},
    "specDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك اضافة اخصائي جديد وامكانية تعديله او حذفه"])},
    "specInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الاخصائي"])},
    "specName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الاخصائي"])},
    "namePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال الاسم"])},
    "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التخصص"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الجوال"])},
    "phonePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال رقم الجوال"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الالكتروني"])},
    "emailPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال البريد الالكتروني"])},
    "conPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعر الاستشارة"])},
    "pricePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال سعر الاستشارة"])},
    "conDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدة الاستشارة"])},
    "durationPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال مدة الاستشارة"])},
    "bio_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نبذة عن الاخصائي بالعربية"])},
    "bio_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نبذة عن الاخصائي بالانجليزية"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اليوم"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من"])},
    "addAnotherDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة موعد اخر"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة"])},
    "specPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء اختيار التخصص"])},
    "countryCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الدولة"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الى"])},
    "workDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مواعيد العمل"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جديدة"])},
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالية"])},
    "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منتهية"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملغية"])},
    "num": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم"])},
    "clientName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم العميل"])},
    "addType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الادمان"])},
    "orderType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الطلب"])},
    "spec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الاخصائي"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير الاستشارة"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسالة"])},
    "textPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال رسالتك"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارسال"])},
    "chooseDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر اليوم"])},
    "delApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" هل انت متأكد من حذف المعاد ؟ "])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نعم"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا"])},
    "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ التعديلات"])},
    "currentAnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالية او قادمة"])}
  },
  "auth": {
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل جديد"])},
    "regDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برجاء ادخال بيانات حسابك حتى تتمكن من التسجيل"])},
    "centerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المركز"])},
    "namePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال اسم المركز"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الجوال"])},
    "changePhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير رقم الجوال"])},
    "phonePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال رقم الجوال"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الالكتروني"])},
    "emailPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال البريد الالكتروني"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النوع"])},
    "spec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التخصص"])},
    "specPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء اختيار التخصص"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدينة"])},
    "cityPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء اختيار مدينة"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
    "addressPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال العنوان على الخريطة"])},
    "licenseName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الترخيص"])},
    "numberPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال رقم الترخيص"])},
    "ownerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المالك"])},
    "ownerPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال اسم المالك"])},
    "bankName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم البنك"])},
    "bankPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال اسم البنك"])},
    "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الايبان"])},
    "ibanPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال رقم الايبان"])},
    "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صور الاعتمادات والخبرات"])},
    "sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
    "loginPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برجاء ادخال بيانات حسابك حتى تتمكن من الدخول"])},
    "haveNoAcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ليس لديك حساب"])},
    "press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضغط هنا"])},
    "actCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود التحقق"])},
    "codePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برجاء ادخال كود التحقق المرسل الى رقم جوالك"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد"])},
    "getNoCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يصلك الكود"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة ارسال "])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل"])}
  },
  "profile": {
    "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادارة الملف الشخصي"])},
    "changePhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير رقم الجوال"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الملف الشخصي"])}
  },
  "dash": {
    "direct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادارة الاستشارات المباشرة"])},
    "directPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متابعة الاستشارات المباشرة"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد الموعد"])},
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالي"])},
    "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منتهي"])},
    "orderInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الطلب"])},
    "orderNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الطلب"])},
    "orderDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الطلب"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الاستشارة"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النوع"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السن"])},
    "asked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التخصص المطلوب"])},
    "way": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طريقة الدفع"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الاستشارة"])},
    "appoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موعد الاستشارة"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدة الاستشارة"])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيمة الاستشارة"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيمة الضريبة"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموع الكلي"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف الحالة"])},
    "canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملغية"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عن التطبيق"])},
    "fqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أسئلة متكررة"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشروط والأحكام"])},
    "arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العربية"])},
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإنجليزية"])},
    "aboutDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك معرفة المزيد عن التطبيق  من هنا"])},
    "termsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك معرفة الشروط والأحكام  من هنا"])},
    "fqsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك معرفة الأسئلة المكررة  من هنا"])}
  },
  "wallet": {
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحفظة"])},
    "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متابعة المحفظة"])},
    "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سحب رصيد"])}
  },
  "treat": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادارة الخطط العلاجية"])},
    "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متابعة الخطط العلاجية"])},
    "keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمات مفتاحية"])},
    "cancelReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سبب رفض العرض"])},
    "orderDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الطلب"])},
    "num": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الطلب"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الطلب"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الاستشارة"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النوع"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السن"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
    "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة الاجتماعية"])},
    "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوظيفة"])},
    "jobType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الوظيفة"])},
    "symp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد اعراض انسحاب "])},
    "mental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل شخصت بأمراض نفسية"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف الحالة"])},
    "freeTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيف تقضي أوقات فراغك"])},
    "timeSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاوقات المناسبة لتنظيم الجلسات"])},
    "diseases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأمراض النفسية التي شخصت بها من قبل"])},
    "clientInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات العميل"])},
    "insts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعليمات والإرشادات"])},
    "medicines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأدوية"])},
    "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخطة العلاجية"])},
    "sessionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الجلسة"])},
    "sessionName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الجلسة"])},
    "spec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التخصص"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدة"])},
    "appoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموعد"])},
    "sessionInst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارشادات الجلسة"])},
    "addDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسناد الجلسة لطبيب"])},
    "sendOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارسال عرض السعر"])},
    "editOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل عرض السعر"])}
  },
  "home": {
    "dash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوحة التحكم"])},
    "docNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["specialists"])},
    "supportNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["supportGroups"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["patients"])},
    "urgentNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["urgentConsultations"])},
    "personsNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subscriptions"])},
    "treatNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["treatmentPlans"])},
    "profits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات الارباح"])},
    "opinions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أراء العملاء"])},
    "noRates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد تقييمات إلي الان"])}
  },
  "offer": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارسال عرض سعر"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة خطط علاجية"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديد عدد الجلسات ومدة العلاج التقريبية "])},
    "num": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الجلسات"])},
    "numPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر عدد الجلسات"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدة انتهاء الخطة (عدد الايام )"])},
    "durationPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب عدد الأيام المتوقعة"])},
    "sessionIndex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجلسة رقم"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الجلسة"])},
    "typePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء تحديد نوع الجلسة"])},
    "spec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التخصص"])},
    "specPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء تحديد التخصص"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الجلسة"])},
    "namePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال اسم الجلسة"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدة"])},
    "timePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال المدة (بالدقائق)"])},
    "inst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارشادات الجلسة"])},
    "instPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال ارشادات الجلسة"])},
    "med": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الادوية والعلاجات"])},
    "medNameAr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الدواء بالعربي"])},
    "arPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال اسم الدواء بالعربي"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الدواء بالانجليزية"])},
    "enPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال اسم الدواء بالانجليزية"])},
    "medImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة الدواء"])},
    "imgPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ارفاق صورة الدواء"])},
    "addMed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة دواء جديد"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر الكلي"])},
    "pricePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال السعر"])}
  },
  "con": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك التواصل مع ادارة التطبيق من هنا"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المركز"])},
    "namePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال اسم المركز"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الجوال"])},
    "phonePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال رقم الجوال"])},
    "title_con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادارة الاستشارات المباشرة"])},
    "desc_con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متابعة الاستشارات المباشرة"])},
    "keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمات مفتاحية"])},
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد استشارات مباشرة إلى الان"])},
    "clientName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم العميل"])},
    "specName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الاخصائي المطلوب"])}
  },
  "group": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادارة مجموعة الدعم"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متابعة مجموعة الدعم"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات المجموعة"])},
    "nameAr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المجموعة بالعربية"])},
    "namePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال اسم المجموعة"])},
    "nameEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المجموعة بالانجليزية"])},
    "descAr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف بالعربية"])},
    "descEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف بالانجليزية"])},
    "descPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال الوصف"])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيمة المجموعة"])},
    "valuePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء تحديد قيمة المجموعة"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر"])},
    "pricePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال السعر"])},
    "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الجلسات"])},
    "countPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال عدد الجلسات"])},
    "seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المقاعد"])},
    "seatsPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال عدد المقاعد"])},
    "session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جلسة"])}
  },
  "session": {
    "nameAr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الجلسة بالعربية"])},
    "namePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال اسم الجلسة"])},
    "nameEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الجلسة بالإنجليزية"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التاريخ"])},
    "datePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال التاريخ"])},
    "appoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموعد"])},
    "appPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال الموعد"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدة"])},
    "durationPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال المدة"])},
    "spec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاخصائي"])},
    "specPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء تحديد الأخصائي المتاح"])},
    "specLoad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جاري تحديد الأخصائيين المتاحيين"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض التفاصيل"])}
  },
  "fin": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادارة الحسابات المالية والأرباح"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متابعة الحسابات المالية والأرباح"])},
    "chooseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر التاريخ"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فلتر حسب"])},
    "chooseType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر النوع"])},
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد حسابات مالية"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاجمالي"])},
    "appPercent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة التطبيق"])},
    "centerCom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة المركز"])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم التقرير"])}
  },
  "message": {
    "specMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسائل الاخصائيين"])},
    "clientMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسائل العملاء"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الجوال"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موضوع الرسالة"])},
    "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محتوى الرسالة"])}
  },
  "not": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاشعارات"])}
  },
  "rate": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اراء العملاء"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك استعراض اراء العملاء من هنا"])},
    "specTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اراء العملاء"])},
    "not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد تقييمات"])}
  },
  "spec": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادارة الاخصائيين"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك اضافة اخصائي جديد وامكانية تعديله او حذفه"])},
    "not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد أخصائيين إلى الان"])},
    "sessionNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الجلسات التي قام بها"])},
    "rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقييم"])},
    "showRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض التقييم"])},
    "deactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الغاء تفعيل الحساب"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفعيل الحساب"])},
    "sendNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارسال اشعار"])},
    "nameAr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان الاشعار بالعربية"])},
    "nameEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان الاشعار بالانجليزية"])},
    "arPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال عنوان الاشعار بالعربية"])},
    "enPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال عنوان الاشعار بالانجليزية"])},
    "contentAr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محتوى الاشعار بالعربية"])},
    "contentEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محتوى الاشعار بالإنجليزية"])},
    "arCon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال محتوى الاشعار بالعربية"])},
    "EnCon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال محتوى الاشعار بالإنجليزية"])}
  },
  "support": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادارة مجموعة الدعم"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متابعة مجموعة الدعم"])},
    "groupInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات المجموعة"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المجموعة"])},
    "reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المقاعد المحجوزة"])}
  },
  "days": {
    "sat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السبت"])},
    "sun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأحد"])},
    "mun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاثنين"])},
    "tue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الثلاثاء"])},
    "wed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأربعاء"])},
    "thu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخميس"])},
    "fri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجمعة"])}
  },
  "add": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة جلسة جديدة"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة جلسة جديدة الى الجلسات الحالية"])},
    "addSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة الجلسة"])},
    "spec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التخصص"])},
    "specPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء تحديد التخصص"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الجلسة"])},
    "namePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال اسم الجلسة"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الجلسة"])},
    "typePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء تحديد نوع الجلسة"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدة"])},
    "durationPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال المدة بالدقائق"])},
    "inst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارشادات الجلسة"])},
    "instPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال ارشادات الجلسة"])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيمة الجلسة"])},
    "valuePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء تحديد قيمة الجلسة"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعر الجلسة"])},
    "pricePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال سعر الجلسة في حالة كانت مدفوعة"])},
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فردية"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جماعية"])},
    "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجانية"])},
    "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدفوعة"])}
  },
  "single": {
    "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انهاء الخطة العلاجية"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدة انتهاء الخطة"])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيمة الخطة العلاجية"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيمة الضريبة"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاجمالي"])},
    "reAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعادة اسناد الجلسة"])},
    "reSech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعادة جدولة الجلسة"])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استعراض تقارير الجلسة"])},
    "rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييم العميل للاخصائي"])},
    "reRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييم الاخصائي للعميل"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الاخصائيين المضافين للجلسة"])},
    "specInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الاخصائي"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قبول التاجيل"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفض التاجيل"])},
    "patientInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات المرضى المضافين للجلسة"])},
    "meats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات المرافقين"])},
    "chooseDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء اختيار الطبيب المسند اليه الخطة"])},
    "sessionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الجلسة"])},
    "datePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برجاء ادخال تاريخ الجلسة"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت الجلسة"])},
    "timePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء اختيار وقت الجلسة"])},
    "doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطبيب"])},
    "docPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الطبيب"])},
    "patient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المرضى"])},
    "patientPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر  المرضى"])}
  }
}