export default {
  "nav": {
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "spec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialists Management"])},
    "direct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direct Consultation Management"])},
    "treat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treatment Plans Management"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support Group Management"])},
    "earn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Accounts and Profits"])},
    "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communication Messages"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])}
  },
  "common": {
    "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
    "noCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Company Name"])},
    "noGender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Gender Selected"])},
    "noAge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Age Added"])},
    "selectedItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["selected specializations"])},
    "ibanNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iban Number Should Be More Than 24 Digits"])},
    "comNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercial Number Should Be More Than 6 Numbers"])},
    "noMessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Messages Found"])},
    "noSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Support Groups Found"])},
    "noTreatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Treatment Plans Found."])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "urgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uregent consultations"])},
    "plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["treatment plans"])},
    "deleteDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are You Sure To Delete The Specialist"])},
    "refuseReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected Reason"])},
    "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["previous"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["next"])},
    "reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All seats have been reserved"])},
    "phoneValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phone number should be more than 9 digits"])},
    "emailValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid email address."])},
    "addApoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the appointments."])},
    "addSpec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Specialist"])},
    "editSpec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Specialist"])},
    "specDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can add a new specialist and have the ability to edit or delete them."])},
    "specInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialist Information"])},
    "specName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialist Name"])},
    "namePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the name"])},
    "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialization"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Number"])},
    "phonePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the mobile number"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "emailPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the email"])},
    "conPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultation Price"])},
    "pricePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the consultation price"])},
    "conDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultation Duration"])},
    "durationPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the consultation duration"])},
    "bio_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brief about the specialist in Arabic"])},
    "bio_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brief about the specialist in English"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "addAnotherDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Another Date"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "specPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the specialization"])},
    "countryCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the country"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
    "workDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working Dates"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current"])},
    "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finished"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])},
    "num": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
    "clientName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Name"])},
    "addType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addiction Type"])},
    "orderType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Type"])},
    "spec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialist Information"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultation Report"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "textPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your message"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "chooseDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the day"])},
    "delApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the appointment?"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Changes"])},
    "currentAnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current or Upcoming"])}
  },
  "auth": {
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Registration"])},
    "regDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your account information to register."])},
    "centerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Center Name"])},
    "namePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the center name"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Number"])},
    "changePhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Mobile Number"])},
    "phonePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the mobile number"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "emailPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the email"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "spec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialization"])},
    "specPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the specialization"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "cityPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the city"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "addressPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the address on the map"])},
    "licenseName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License Number"])},
    "numberPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the license number"])},
    "ownerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner Name"])},
    "ownerPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the owner name"])},
    "bankName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Name"])},
    "bankPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the bank name"])},
    "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN Number"])},
    "ibanPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the IBAN number"])},
    "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credentials and Experiences Photos"])},
    "sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "loginPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your account information to login"])},
    "haveNoAcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't have an account"])},
    "press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here"])},
    "actCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification Code"])},
    "codePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the verification code sent to your mobile number"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "getNoCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Did not receive the code"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])}
  },
  "profile": {
    "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile Management"])},
    "changePhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Mobile Number"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile Information"])}
  },
  "dash": {
    "direct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direct Consultation Management"])},
    "directPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow Direct Consultations"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Appointment"])},
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current"])},
    "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finished"])},
    "orderInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Information"])},
    "orderNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Number"])},
    "orderDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Date"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultation Type"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
    "asked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested Specialization"])},
    "way": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Method"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultation Date"])},
    "appoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultation Appointment"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultation Duration"])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultation Value"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Value"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Amount"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Case Description"])},
    "canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceled"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the Application"])},
    "fqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequently Asked Questions"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and Conditions"])},
    "arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic"])},
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "aboutDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more about the application from here"])},
    "termsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn the terms and conditions from here"])},
    "fqsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get answers to frequently asked questions from here"])}
  },
  "wallet": {
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet"])},
    "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep track of the wallet"])},
    "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw Balance"])}
  },
  "treat": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treatment Plans Management"])},
    "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow Treatment Plans"])},
    "keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keywords"])},
    "cancelReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for Offer Rejection"])},
    "orderDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Information"])},
    "num": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Number"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Date"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultation Type"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social Status"])},
    "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job"])},
    "jobType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Type"])},
    "symp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are there withdrawal symptoms"])},
    "mental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have you been diagnosed with mental illnesses"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Case Description"])},
    "freeTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How do you spend your free time"])},
    "timeSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suitable times for organizing sessions"])},
    "diseases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Psychological diseases previously diagnosed"])},
    "clientInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Information"])},
    "insts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructions and Guidance"])},
    "medicines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medications"])},
    "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treatment Plan"])},
    "sessionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Type"])},
    "sessionName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Name"])},
    "spec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialization"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
    "appoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointment"])},
    "sessionInst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Instructions"])},
    "addDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign Session to a Doctor"])},
    "sendOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Price Offer"])},
    "editOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Price Offer"])}
  },
  "home": {
    "dash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "docNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["specialists"])},
    "supportNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["supportGroups"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["patients"])},
    "urgentNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["urgentConsultations"])},
    "personsNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subscriptions"])},
    "treatNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["treatmentPlans"])},
    "profits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profits statistics"])},
    "opinions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Opinions"])},
    "noRates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Rates Till Now"])}
  },
  "offer": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Price Offer"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Treatment Plans"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify the number of sessions and approximate treatment duration"])},
    "num": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Sessions"])},
    "numPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the number of sessions"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan Duration (Number of Days)"])},
    "durationPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the expected number of days"])},
    "sessionIndex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Number"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Type"])},
    "typePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the session type"])},
    "spec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialization"])},
    "specPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the specialization"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Name"])},
    "namePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the session name"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
    "timePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the duration (in minutes)"])},
    "inst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Instructions"])},
    "instPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter session instructions"])},
    "med": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medications and Treatments"])},
    "medNameAr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medication Name in Arabic"])},
    "arPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the medication name in Arabic"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medication Name in English"])},
    "enPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the medication name in English"])},
    "medImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medication Image"])},
    "imgPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please attach the medication image"])},
    "addMed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Medication"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Price"])},
    "pricePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the price"])}
  },
  "con": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can contact the application management from here"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Center Name"])},
    "namePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the center name"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Number"])},
    "phonePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the mobile number"])},
    "title_con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direct Consultation Management"])},
    "desc_con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow direct consultations"])},
    "keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keywords"])},
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No direct consultations yet"])},
    "clientName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Name"])},
    "specName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required Specialist Name"])}
  },
  "group": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support Group Management"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow the support group"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Data"])},
    "nameAr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Name in Arabic"])},
    "namePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the group name"])},
    "nameEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Name in English"])},
    "descAr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description in Arabic"])},
    "descEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description in English"])},
    "descPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the description"])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Value"])},
    "valuePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please specify the group value"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "pricePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the price"])},
    "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Sessions"])},
    "countPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the number of sessions"])},
    "seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Seats"])},
    "seatsPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the number of seats"])},
    "session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session"])}
  },
  "session": {
    "nameAr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Name in Arabic"])},
    "namePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the session name"])},
    "nameEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Name in English"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "datePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the date"])},
    "appoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointment"])},
    "appPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the appointment"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
    "durationPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the duration"])},
    "spec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialist"])},
    "specPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the available specialist"])},
    "specLoad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading available specialists"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Details"])}
  },
  "fin": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Accounts and Profits Management"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow financial accounts and profits"])},
    "chooseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the date"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by"])},
    "chooseType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the type"])},
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No financial accounts found"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "appPercent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application Percentage"])},
    "centerCom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Center Percentage"])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Number"])}
  },
  "message": {
    "specMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialist Messages"])},
    "clientMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Messages"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Number"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message Subject"])},
    "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message Content"])}
  },
  "not": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])}
  },
  "rate": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialist Ratings"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can review specialist ratings from here"])},
    "specTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialist Ratings"])},
    "not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No ratings yet"])}
  },
  "spec": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialists Management"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can add a new specialist and have the ability to edit or delete them"])},
    "not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No specialists yet"])},
    "sessionNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of sessions conducted"])},
    "rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
    "showRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Rating"])},
    "deactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivate Account"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate Account"])},
    "sendNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Notification"])},
    "nameAr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification Title in Arabic"])},
    "nameEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification Title in English"])},
    "arPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the notification title in Arabic"])},
    "enPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the notification title in English"])},
    "contentAr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification Content in Arabic"])},
    "contentEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification Content in English"])},
    "arCon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the notification content in Arabic"])},
    "EnCon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the notification content in English"])}
  },
  "support": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support Group Management"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow the support group"])},
    "groupInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Data"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Name"])},
    "reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserved Seats"])}
  },
  "days": {
    "sat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday"])},
    "sun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunday"])},
    "mun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])},
    "tue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday"])},
    "wed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wednesday"])},
    "thu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday"])},
    "fri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday"])}
  },
  "add": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Session"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new session to the current sessions"])},
    "addSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Session"])},
    "spec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialization"])},
    "specPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the specialization"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Name"])},
    "namePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the session name"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Type"])},
    "typePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the session type"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
    "durationPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the duration in minutes"])},
    "inst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Instructions"])},
    "instPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter session instructions"])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Value"])},
    "valuePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please specify the session value"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Price"])},
    "pricePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the session price if it is paid"])},
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["individual"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["group"])},
    "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["free"])},
    "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paid"])}
  },
  "single": {
    "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish Treatment Plan"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treatment Plan Duration"])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treatment Plan Value"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Amount"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "reAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reassign Session"])},
    "reSech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reschedule Session"])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Reports Review"])},
    "rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Rating for Specialist"])},
    "reRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialist Rating for Client"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added Specialists' Data for the Session"])},
    "specInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialist Data"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept Postponement"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuse Postponement"])},
    "patientInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added Patients' Data for the Session"])},
    "meats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accompanying Persons Data"])},
    "chooseDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Choose the Assigned Doctor for the Plan"])},
    "sessionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Date"])},
    "datePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Enter the Session Date"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Time"])},
    "timePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Choose the Session Time"])},
    "doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor"])},
    "docPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the Doctor"])},
    "patient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patients"])},
    "patientPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Patients"])}
  },
  "year": {
    "jan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January"])},
    "feb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February"])},
    "march": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March"])},
    "april": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
    "May": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
    "jun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June"])},
    "july": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["July"])},
    "aug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aug"])},
    "sep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sep"])},
    "oct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oct"])},
    "nov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nov"])},
    "dec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dec"])}
  }
}